// basic imports
import React from "react";

// custom library imports for page
import DaisyUITable from "components/DaisyUITable";
import SimpleForm from "components/SimpleForm/SimpleForm.js";
import { httpRequest } from "helpers/httpClient/httpClient";
import { onResponse } from "helpers/httpClient/onResponse";
import YesNoModal from "components/YesNoModal/YesNoModal";
import {
  Row,
  Col,
  CustomModal,
  CustomButton,
} from "components/CustomComponents";

// external libraries imports
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Progress,
  Input,
  InputGroup,
  Modal,
  Table,
} from "react-daisyui";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import TimeAgo from "javascript-time-ago";
import { FaHeartCirclePlus, FaRotate } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { keyToTitle } from "constants/general/appAnalyticsMapping";
import { useSearchParams } from "react-router-dom";

function ListAllAsthmaMonitoring() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = React.useState("");
  const [patientId, setPatientId] = React.useState("");
  const [patients, setPatients] = React.useState([{ text: "All", value: "" }]);
  const [data, setData] = React.useState([]);
  const [callAPIReady, setCallAPIReady] = React.useState(false);

  const [pageState, setPageState] = React.useState(1);
  const [totalSize, setTotalSize] = React.useState(0);
  const [sizePerPageState, setSizePerPageState] = React.useState(50);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [forward, setForward] = React.useState(null);
  const [yesNoModal1Forward, setYesNoModal1Forward] = React.useState(null);
  const [yesNoModal1Show, setYesNoModal1Show] = React.useState(null);
  const [id, setId] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const timeAgo = new TimeAgo("en-US");
  const columns = [
    {
      dataField: "studyIdCol",
      text: "Study ID",
    },
    {
      dataField: "nameCol",
      text: "Patient Name",
    },
    {
      dataField: "detailCol",
      text: "Details",
    },
    {
      dataField: "symptomCol",
      text: "Symptoms",
    },
    {
      dataField: "dateCol",
      text: "Date",
    },
  ];
  const form = [
    // {
    //   size: "12",
    //   name: "Search",
    //   type: "text",
    //   value: search,
    //   onChange: (e) => {
    //     setSearch(e.target.value);
    //   },
    // },
    {
      size: "12",
      name: "Patient",
      type: "select",
      options: patients,
      value: patientId,
      onChange: (e) => {
        setSearchParams({ patientId: e.target.value });
      },
    },
  ];

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function callAPI(_sizePerPage, _currentIndex) {
    let reqBody = {
      search: search,
      currentIndex: _currentIndex,
      sizePerPage: _sizePerPage,
    };
    if (patientId) {
      reqBody.patientId = patientId;
    }
    httpRequest("/asthmaMonitoring/list", "POST", reqBody, listCB.bind(this));
  }

  function doListPatientsCB(jsonObj) {
    let options = [{ text: "All", value: "" }];
    if (jsonObj.success === true) {
      let patientArray = jsonObj.data.doc;
      for (var i = 0; i < patientArray.length; i++) {
        options.push({
          text: patientArray[i].studyId + " - " + patientArray[i].name,
          value: patientArray[i]._id,
        });
      }
    } else {
      toast(onResponse(jsonObj.code, jsonObj.message));
    }
    setPatients(options);
  }

  function handleTableChange(page, sizePerPage) {
    var currentIndex = (page - 1) * sizePerPage;
    setCurrentIndex(currentIndex);
    setPageState(page);
    setSizePerPageState(sizePerPage);
    setCallAPIReady(true);
  }

  function doSubmit() {
    setPageState(1);
    setCurrentIndex(0);
    setCallAPIReady(true);
  }

  function doReset() {
    setSearch("");
    setPatientId("");
    setCallAPIReady(true);
  }

  function listCB(jsonObj) {
    console.log(jsonObj);
    if (jsonObj.success === true) {
      setTotalSize(jsonObj.data.count);

      for (var i = 0; i < jsonObj.data.doc.length; i++) {
        var doc = jsonObj.data.doc[i];
        jsonObj.data.doc[i].studyIdCol = (
          <>
            <div>
              <span style={{ whiteSpace: "pre-line" }}>
                {(doc.patient && doc.patient.studyId && doc.patient.studyId) ||
                  "-"}
              </span>
            </div>
          </>
        );

        jsonObj.data.doc[i].nameCol = (
          <>
            <div>
              <span style={{ whiteSpace: "pre-line" }}>
                {(doc.patient && doc.patient.name && doc.patient.name) || "-"}
              </span>
            </div>
          </>
        );

        if (doc.symptoms && doc.symptoms.length) {
          jsonObj.data.doc[i].symptomCol = (
            <>
              <div>
                <Table size="xs">
                  <Table.Head>
                    <span style={{ whiteSpace: "pre-line" }}>Symptom</span>
                    <span style={{ whiteSpace: "pre-line" }}>Severity</span>
                  </Table.Head>
                  <Table.Body>
                    {doc.symptoms.map((item, index) => {
                      return (
                        <Table.Row key={index}>
                          <span>{item.symptom}</span>
                          <span style={{ whiteSpace: "pre-line" }}>
                            {mapSeverity(item)}
                          </span>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            </>
          );
        }

        jsonObj.data.doc[i].detailCol = (
          <>
            {renderLine(
              "Peak Flow Reading",
              (doc.peakFlowReading || "-") + " L/min"
            )}
            {renderLine("Location", doc.location || "-")}
            {renderLine("Feedback", doc.feedback || "-")}
          </>
        );

        if (doc.createDate) {
          jsonObj.data.doc[i].dateCol = (
            <>
              <div>
                <span style={{ whiteSpace: "pre-line" }}>
                  {moment(doc.createDate).format("YYYY-MM-DD HH:mm A")}
                </span>
              </div>
            </>
          );
        }
      }

      setData(jsonObj.data.doc);
    } else {
      toast(onResponse(jsonObj.code, jsonObj.message));
    }
  }

  function mapSeverity(symptomObj) {
    if (!symptomObj || !("severity" in symptomObj)) {
      return "N/A";
    }
    let severity = parseInt(symptomObj.severity);

    switch (severity) {
      case 0:
        return "None";
      case 1:
        return "Hardly Ever";
      case 2:
        return "Several Times a Day";
      case 3:
        return "Most of the Time";
      case 4:
        return "The Whole Day";

      default:
        break;
    }
  }

  function renderLine(title, value) {
    return (
      <div>
        <span style={{ whiteSpace: "pre-line" }}>
          {title + ": "}
          <b>{value || "-"}</b>
        </span>
      </div>
    );
  }

  React.useEffect(() => {
    if (searchParams.get("patientId")) {
      setPatientId(searchParams.get("patientId"));
    }
    setCallAPIReady(true);
  }, [searchParams]);

  React.useEffect(() => {
    httpRequest("/patient/listAll", "POST", {}, doListPatientsCB.bind(this));
  }, []);

  if (callAPIReady) {
    setCallAPIReady(false);
    callAPI(sizePerPageState, currentIndex);
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="mb-0">List Asthma Monitoring</h3>
          </Card.Title>

          <div className="mt-5 flex items-center">
            <div className="mr-4 w-full">
              {form.map((item, key) => {
                return (
                  <SimpleForm
                    key={key}
                    size={item.size}
                    type={item.type}
                    value={item.value}
                    options={item.options}
                    placeholder={item.name}
                    onChange={item.onChange}
                    className={"border-none bg-gray-100 px-4 py-5 outline-none"}
                  />
                );
              })}
            </div>

            <div className="flex items-center justify-center">
              <CustomButton
                message={"Search"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-black"
                }
                onClick={(e) => {
                  e.preventDefault();
                  doSubmit();
                }}
              >
                <IoSearch size={20} />
              </CustomButton>

              <CustomButton
                message={"Reset"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-black"
                }
                onClick={(e) => {
                  e.preventDefault();
                  doReset();
                }}
              >
                <FaRotate size={18} />
              </CustomButton>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Title className="justify-center py-2">
          <h3 className="mb-0">List Asthma Monitoring</h3>
        </Card.Title>
        <DaisyUITable
          columns={columns}
          data={data}
          page={pageState}
          sizePerPage={sizePerPageState}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      </Card>

      <YesNoModal forward={yesNoModal1Forward} show={yesNoModal1Show} />
      <Toaster />
    </div>
  );
}

export default ListAllAsthmaMonitoring;
