import React from "react";
import { Button } from "react-daisyui";
import { doctorRoutes } from "constants/routes";
import { Link } from "react-router-dom";

function DoctorNavigation() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [locationRef, setLocationRef] = React.useState("");

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const styles = {
    row: {
      display: "flex",
      overflow: "auto",
    },
    column: {
      padding: 15,
      paddingTop: 0,
    },
    icon: {
      borderRadius: "50%",
      width: "80px",
      height: "80px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      boxShadow: "0 6px 4px rgba(100, 100, 100, 0.15)",
    },
    title: {
      width: "80px",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      display: "flex",
      fontWeight: 500,
      fontSize: 13,
      color: "#0b0b0b",
    },
  };

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  const isCurrentRoute = (route) => {
    return locationRef.indexOf(route) !== -1;
  };

  React.useEffect(() => {
    setLocationRef(window.location.href);
  }, [window.location.href]);

  return (
    <>
      <div style={styles.row}>
        {doctorRoutes.map((item, key) => {
          var s = { ...styles.icon };

          return (
            <div style={styles.column} key={"navigation-key-" + key}>
              <Link to={item.route}>
                <div
                  className={`transition-colors delay-100 ease-in-out ${isCurrentRoute(item.route) ? "bg-cyan-500" : "bg-cyan-300"} hover:bg-cyan-400`}
                  style={{
                    ...s,
                  }}
                >
                  {item.icon}
                </div>
                <div className="pt-1" style={styles.title}>
                  {item.title}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default DoctorNavigation;
