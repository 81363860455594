import {
  MdMonitor,
  MdOutlineAnalytics,
  MdOutlineAutoGraph,
} from "react-icons/md";
import { RiUserHeartLine } from "react-icons/ri";

const doctorRoutes = [
  {
    title: "PATIENTS",
    icon: (
      <RiUserHeartLine
        size={50}
        fill="white"
        style={{ objectFit: "cover" }}
        alt=""
      />
    ),
    route: "/doctor/patient/list",
  },
  {
    title: "ASTHMA MONITORING",
    icon: (
      <MdMonitor size={50} fill="white" style={{ objectFit: "cover" }} alt="" />
    ),
    route: "/doctor/asthmaMonitoring/list",
  },
  {
    title: "ANALYTICS",
    icon: (
      <MdOutlineAnalytics
        size={50}
        fill="white"
        style={{ objectFit: "cover" }}
        alt=""
      />
    ),
    route: "/doctor/appAnalytic/list",
  },
  {
    title: "FORECAST",
    icon: (
      <MdOutlineAutoGraph
        size={50}
        fill="white"
        style={{ objectFit: "cover" }}
        alt=""
      />
    ),
    route: "/doctor/forecastData/list",
  },
];

export { doctorRoutes };
