// basic imports
import React from "react";

// custom library imports for page
import SimpleForm from "components/SimpleForm/SimpleForm";
import { validateEmail, validatePhone } from "helpers/validator";
import { httpRequest } from "helpers/httpClient/httpClient";
import { onResponse } from "helpers/httpClient/onResponse";
import YesNoModal from "components/YesNoModal/YesNoModal";
import { Row, Col } from "components/CustomComponents";
import CONST from "constants/formConstants/constPatient";

// external libraries imports
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Progress,
  Input,
  InputGroup,
  Modal,
  Table,
} from "react-daisyui";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

function BasePatient(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [forward, setForward] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [data, setData] = React.useState({});
  const [ready, setReady] = React.useState(false);

  const [description, setDescription] = React.useState("");
  const [api, setApi] = React.useState([]);
  const [apiSelection, setApiSelection] = React.useState([]);
  const [_id, setId] = React.useState("");
  const [yesNoModal1Forward, setYesNoModal1Forward] = React.useState(null);
  const [yesNoModal1Show, setYesNoModal1Show] = React.useState(null);
  const [callAPIReady, setCallAPIReady] = React.useState(false);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  let patientForm = null; // Basic Info

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function doListApi(jsonObj) {
    if (jsonObj.success) {
      var options = [];
      for (let i = 0; i < jsonObj.data.doc.length; i++) {
        options.push({
          text: jsonObj.data.doc[i].path,
          value: jsonObj.data.doc[i]._id,
        });
      }
      setApiSelection(options);
    }
  }

  function callAPI() {
    httpRequest(
      "/patient/view",
      "POST",
      {
        _id: props.forward._id,
      },
      doView.bind(this)
    );
    setCallAPIReady(false);
  }

  function createObj(k) {
    var obj = {
      size: k.size,
      name: k.label,
      id: k.key,
      type: k.type,
      height: k.height,
      options: k.options,
      value: k.key in data ? data[k.key] : "",
      disabled: disabled ? disabled : k.disable,
      required: k.required,
      onChange: (e) => {
        var data_ = { ...data };

        if (e.target.type === "number") {
          data_[e.target.id] = parseFloat(e.target.value);
        } else data_[e.target.id] = e.target.value;

        setData(data_);
      },
    };

    // obj.options = [];

    return obj;
  }

  function reset() {}

  function doSubmitCB(jsonObj) {
    if (jsonObj.success) {
      if ("forward" in props) {
        props.forward.cb();
      } else {
        reset();
      }
    }
    toast(onResponse(jsonObj.code, jsonObj.message));
  }

  function doSubmit() {
    var data_ = {};
    var error = false;

    for (var i = 0; i < CONST.keyLabelPatientInfo.length; i++) {
      data_[CONST.keyLabelPatientInfo[i].key] =
        data[CONST.keyLabelPatientInfo[i].key];
      if (
        CONST.keyLabelPatientInfo[i].required === true &&
        (!data[CONST.keyLabelPatientInfo[i].key] ||
          data[CONST.keyLabelPatientInfo[i].key] === "")
      ) {
        toast("Please Enter " + CONST.keyLabelPatientInfo[i].label + "!");
        error = true;
      }
    }

    if (error) return;

    if (!validateEmail(data_.email)) {
      toast("Please enter a valid email");
      error = true;
    }

    if (!validatePhone(data_.mobile)) {
      toast("Please enter a valid phone number");
      error = true;
    }

    if (error) return;

    if ("forward" in props && props.forward._id) {
      httpRequest(
        "/patient/update",
        "POST",
        {
          _id: props.forward._id,
          data: data_,
        },
        doSubmitCB.bind(this)
      );
    } else {
      if (data.password && data.password !== "") {
        data_.password = data.password;
      }
      httpRequest(
        "/patient/create",
        "POST",
        {
          data: data_,
        },
        doSubmitCB.bind(this)
      );
    }
  }

  function doView(jsonObj) {
    if (jsonObj.success) {
      setId(jsonObj.data._id);
      var data_ = jsonObj.data;

      setData(data_);
      setReady(true);
    } else {
      toast(onResponse(jsonObj.code, jsonObj.message));
      props.forward.cb();
    }
  }

  function renderAsthmaCondition() {
    if (!data || !data.asthmaCondition || !data.asthmaCondition.length) {
      return;
    }

    return (
      <>
        <hr className="my-3" />
        <Row>
          <Col style={"p-2"}>
            <h1 className="text-center text-xl font-bold pb-2">
              Asthma Condition
            </h1>
            <Table className="border">
              <Table.Head>
                <span style={{ whiteSpace: "pre-line" }}>Question</span>
                <span style={{ whiteSpace: "pre-line" }}>Answer</span>
              </Table.Head>
              <Table.Body>
                {data.asthmaCondition.map((item, index) => {
                  return (
                    <Table.Row key={index}>
                      <span>{item.question}</span>
                      <span style={{ whiteSpace: "pre-line" }}>
                        {item.severity ? (
                          <b className="text-red-600">Yes</b>
                        ) : (
                          <b className="text-green-500">No</b>
                        )}
                      </span>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Col>
        </Row>
      </>
    );
  }

  React.useEffect(() => {
    if ("forward" in props && props.forward._id) {
      setForward(props.forward);
      setCallAPIReady(true);
    } else {
      setReady(true);
    }
  }, []);

  if (callAPIReady) {
    callAPI();
  }

  if (ready && patientForm === null) {
    let form_ = [];
    for (var i = 0; i < CONST.keyLabelPatientInfo.length; i++) {
      form_.push(createObj(CONST.keyLabelPatientInfo[i]));
    }
    patientForm = form_;
  }

  if (ready && patientForm !== null) {
    return (
      <>
        <Card>
          <Card.Body>
            <Form onSubmit={doSubmit}>
              <div className="pl-lg-4">
                <Row totalCol={12}>
                  {patientForm.map((item, key) => {
                    return (
                      <SimpleForm
                        key={key}
                        id={item.id}
                        size={item.size}
                        name={item.name}
                        type={item.type}
                        disabled={item.disabled}
                        value={item.value}
                        onChange={item.onChange}
                        options={item.options}
                        required={item.required}
                        height={item.height}
                      />
                    );
                  })}
                  {"forward" in props && props.forward._id ? (
                    <div />
                  ) : (
                    <SimpleForm
                      key={"password-input"}
                      id={"password-input"}
                      size={12}
                      name={"Password"}
                      type={"text"}
                      disabled={""}
                      value={data.password}
                      onChange={(e) => {
                        var data_ = { ...data };
                        data_.password = e.target.value;
                        setData(data_);
                      }}
                      required={true}
                    />
                  )}
                </Row>
                {renderAsthmaCondition()}
                <Row style={{ paddingTop: 10 }}>
                  <Col>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        doSubmit();
                      }}
                    >
                      {"forward" in props && props.forward._id
                        ? "Update"
                        : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </Card.Body>
        </Card>
        <YesNoModal forward={yesNoModal1Forward} show={yesNoModal1Show} />
      </>
    );
  } else {
    return <div />;
  }
}

export default BasePatient;
