import React from "react";

// Importing all created components
import { Outlet, useNavigate } from "react-router-dom";
import DoctorHeader from "components/header/DoctorHeader";
import DoctorFooter from "components/footer/DoctorFooter";
import { checkSession } from "helpers/utils";
import DoctorNavbar from "components/navbar/DoctorNavbar";
import DoctorNavigation from "components/navigation/DoctorNavigation";

// Pass the child props
export default function DoctorLayout() {
  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const navigate = useNavigate();
  const redirectToPage = () => {
    navigate("/auth/login");
  };
  checkSession(redirectToPage);

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  return (
    <div className="flex flex-col h-[100%]">
      <DoctorHeader />
      <div className="relative z-10 -mt-10 sm:mx-0 md:mx-[5%] flex-grow flex flex-col">
        <DoctorNavbar />
        <div className="bg-white bg-opacity-70 p-2 flex-grow">
          <DoctorNavigation />
          <Outlet />
        </div>
      </div>
      <DoctorFooter />
    </div>
  );
}
