import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "components/CustomComponents";
import { IoHomeOutline, IoLogOutOutline } from "react-icons/io5";
import { MdVerticalAlignTop } from "react-icons/md";

function DoctorNavbar(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [userObj, setUserObj] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const navButtonClassname =
    "cursor-pointer hover:bg-slate-200 transition-all p-3 rounded-full";

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  React.useEffect(() => {
    var userObject = JSON.parse(localStorage.getItem("userObject"));
    if (userObject) {
      setUserObj(userObject);
    }
  }, []);

  return (
    <div className="h-20 sticky -top-1 z-50 w-full items-center border-b-2 border-b-gray-300 bg-white bg-opacity-95 flex justify-between px-10">
      <div className="flex">
        <div className="dropdown dropdown-end z-[999]">
          <label
            tabIndex={0}
            className="mr-3 flex items-center text-lg font-medium"
          >
            {userObj
              ? `Welcome Dr. ${userObj.lastName ?? userObj.firstName ?? userObj.name ?? ""}!`
              : "Welcome Dr.!"}
          </label>
          <ul
            tabIndex={0}
            className="menu-normal menu dropdown-content rounded-box mt-3 w-56 bg-white p-2 text-sm shadow"
          >
            <li>
              <a className="text-sm">Profile</a>
            </li>
            <li>
              <a className="justify-between text-sm">Settings</a>
            </li>
            <li>
              <Link href={"/home"}>
                <button className="text-sm">Sign Out</button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <Col colSize={1} style={navButtonClassname}>
          <Link to={"/doctor/patient/list"}>
            <IoHomeOutline size={24} />
          </Link>
        </Col>
        {/* <Col colSize={1} style={navButtonClassname}>
          <div
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <MdVerticalAlignTop size={24} />
          </div>
        </Col> */}
        <Col colSize={1} style={navButtonClassname}>
          <Link to={"/home"}>
            <IoLogOutOutline size={24} />
          </Link>
        </Col>
      </div>
    </div>
  );
}

export default DoctorNavbar;
