// basic imports
import React from "react";

// custom library imports for page
import DaisyUITable from "components/DaisyUITable";
import SimpleForm from "components/SimpleForm/SimpleForm.js";
import { httpRequest } from "helpers/httpClient/httpClient";
import { onResponse } from "helpers/httpClient/onResponse";
import YesNoModal from "components/YesNoModal/YesNoModal";
import {
  Row,
  Col,
  CustomModal,
  CustomButton,
} from "components/CustomComponents";

// external libraries imports
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Progress,
  Input,
  InputGroup,
  Modal,
} from "react-daisyui";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import TimeAgo from "javascript-time-ago";
import { MdAdd, MdDelete, MdEdit, MdMonitor } from "react-icons/md";
import { FaHeartCirclePlus, FaRotate } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { GiHealthNormal, GiHeartPlus } from "react-icons/gi";
import { BsClipboard2PlusFill } from "react-icons/bs";

function ListAllForecastData() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState([]);
  const [callAPIReady, setCallAPIReady] = React.useState(false);

  const [pageState, setPageState] = React.useState(1);
  const [totalSize, setTotalSize] = React.useState(0);
  const [sizePerPageState, setSizePerPageState] = React.useState(50);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [forward, setForward] = React.useState(null);
  const [yesNoModal1Forward, setYesNoModal1Forward] = React.useState(null);
  const [yesNoModal1Show, setYesNoModal1Show] = React.useState(null);
  const [id, setId] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const timeAgo = new TimeAgo("en-US");
  const columns = [
    {
      dataField: "dateCol",
      text: "Date",
    },
    {
      dataField: "aqiCol",
      text: "Air Quality Index",
    },
    {
      dataField: "pm25Col",
      text: "PM2.5",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  const form = [
    {
      size: "12",
      name: "Search",
      type: "text",
      value: search,
      onChange: (e) => {
        setSearch(e.target.value);
      },
    },
  ];

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function callAPI(_sizePerPage, _currentIndex) {
    httpRequest(
      "/forecastData/list",
      "POST",
      {
        search: search,
        currentIndex: _currentIndex,
        sizePerPage: _sizePerPage,
      },
      listCB.bind(this)
    );
  }

  function handleTableChange(page, sizePerPage) {
    var currentIndex = (page - 1) * sizePerPage;
    setCurrentIndex(currentIndex);
    setPageState(page);
    setSizePerPageState(sizePerPage);
    setCallAPIReady(true);
  }

  function doSubmit() {
    setPageState(1);
    setCurrentIndex(0);
    setCallAPIReady(true);
  }

  function doReset() {
    setSearch("");
    setCallAPIReady(true);
  }

  function listCB(jsonObj) {
    if (jsonObj.success === true) {
      setTotalSize(jsonObj.data.count);

      for (var i = 0; i < jsonObj.data.doc.length; i++) {
        var doc = jsonObj.data.doc[i];
        if (doc.date && doc.date !== "") {
          jsonObj.data.doc[i].dateCol = (
            <>
              <div>
                <span style={{ whiteSpace: "pre-line" }}>
                  {`${doc.date.slice(0, 4)}-${doc.date.slice(4, 6)}-${doc.date.slice(6, 8)}`}
                </span>
              </div>
            </>
          );
        } else {
          jsonObj.data.doc[i].dateCol = "-";
        }

        if (doc.dailyForecastData && doc.dailyForecastData.length) {
          let data1 = doc.dailyForecastData[0];
          let data2 = doc.dailyForecastData[1];
          jsonObj.data.doc[i].aqiCol = (
            <>
              <div>
                <span style={{ whiteSpace: "pre-line" }}>
                  {"Current: "}
                  {data1.aqi}
                </span>
                <br />
                <span style={{ whiteSpace: "pre-line" }}>
                  {"Tomorrow: "}
                  {data2.aqi}
                </span>
              </div>
            </>
          );

          jsonObj.data.doc[i].pm25Col = (
            <>
              <div>
                <span style={{ whiteSpace: "pre-line" }}>
                  {"Current: "}
                  {parseFloat(data1.PM2_5).toFixed(4)}
                </span>
                <br />
                <span style={{ whiteSpace: "pre-line" }}>
                  {"Tomorrow: "}
                  {parseFloat(data2.PM2_5).toFixed(4)}
                </span>
              </div>
            </>
          );
        }
      }

      setData(jsonObj.data.doc);
    } else {
      toast(onResponse(jsonObj.code, jsonObj.message));
    }
  }

  React.useEffect(() => {
    setCallAPIReady(true);
  }, []);

  if (callAPIReady) {
    setCallAPIReady(false);
    callAPI(sizePerPageState, currentIndex);
  }

  return (
    <div>
      {/* <Card>
        <Card.Body>
          <Card.Title>
            <h3 className="mb-0">List Forecast Data</h3>
          </Card.Title>

          <div className="mt-5 flex items-center">
            <div className="mr-4 w-full">
              {form.map((item, key) => {
                return (
                  <SimpleForm
                    key={key}
                    size={item.size}
                    type={item.type}
                    value={item.value}
                    placeholder={item.name}
                    onChange={item.onChange}
                    className={"border-none bg-gray-100 px-4 py-5 outline-none"}
                  />
                );
              })}
            </div>

            <div className="flex items-center justify-center">
              <CustomButton
                message={"Search"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-black"
                }
                onClick={(e) => {
                  e.preventDefault();
                  doSubmit();
                }}
              >
                <IoSearch size={20} />
              </CustomButton>

              <CustomButton
                message={"Reset"}
                className={
                  "h-11 rounded-full border-none bg-transparent text-black"
                }
                onClick={(e) => {
                  e.preventDefault();
                  doReset();
                }}
              >
                <FaRotate size={18} />
              </CustomButton>
            </div>
          </div>
        </Card.Body>
      </Card> */}

      <Card className="mt-2">
        <Card.Title className="justify-center py-2">
          <h3 className="mb-0">List Forecast Data</h3>
        </Card.Title>
        <DaisyUITable
          columns={columns}
          data={data}
          page={pageState}
          sizePerPage={sizePerPageState}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      </Card>

      <YesNoModal forward={yesNoModal1Forward} show={yesNoModal1Show} />
      <Toaster />
    </div>
  );
}

export default ListAllForecastData;
