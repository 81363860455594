const keyToTitle = {
  userLogin: "User Login",
  visitSettingPage: "Visit Setting Page",
  visitMainPage: "Visit Main Page",
  visitSymptomMainPage: "Visit Symptom Main Page",
  visitSymptomInputPage: "Visit Symptom Input Page",
  visitMapPage: "Visit Map Page",
  submitAsthmaCondition: "Submit Asthma Condition",
  submitNotificationTime: "Submit Notification Time",
  createNewSymptom: "Create New Symptom",
  deleteSymptom: "Delete Symptom",
};

export { keyToTitle };
